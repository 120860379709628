<template>
  <div class="page-index">
    <Header
      background-image="images/hero.jpg"
      class="header--center header--center-bottom header--white--text"
    >
      <div class="container">
        <h2 class="card__title">
          {{ cardTitle }}
        </h2>

        <span class="card__text">
          {{ cardText }}
        </span>

        <TCard class="header__card">
          <div class="card__form">
            <PostalFormStep :progress-value="0" />
          </div>
        </TCard>

        <span class="card__subtext">
          {{ cardSubtext }}
        </span>
      </div>
    </Header>
    <HomeBlock id="usps">
      <Usps :usps="usps" />
    </HomeBlock>
  </div>
</template>

<script>
import AbstractHomePage from 'chimera/all/components/page/AbstractHomePage'
import HomeBlock from 'chimera/all/components/HomeBlock'
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import Header from 'chimera/all/themes/blueflow/components/layout/Header'
import PostalFormStep from '~/components/form/steps/postal/PostalFormStep.vue'

export default {
  components: {
    Header,
    HomeBlock,
    PostalFormStep,
    Usps,
  },

  extends: AbstractHomePage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Tus-inmobiliarias.es',
      headTitleTemplate: '%s',
      headDescription:
        'Introduce tu código postal y compara 6 cotizaciones de los principales agentes inmobiliarios de tu región de forma gratuita. ✓ Tu agente inmobiliario al mejor precio ✓ Ahorra hasta un 40 % ✓ ¡100 % gratis!',
      path: '/',
      usps: [
        {
          title: 'Responde algunas preguntas',
          text: 'Dinos lo que está buscando para que podamos conectarte con el agente inmobiliario adecuado de tu región.',
          logo: require('chimera/all/themes/blueflow/images/usps/pencil.svg'),
        },
        {
          title: 'Recibe cotizaciones gratuitamente',
          text: 'Recibirás hasta seis cotizaciones de agentes inmobiliarios que se ajusten a tus necesidades. ¡Con esto te ahorrarás hasta un 40 %!',
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg'),
        },
        {
          title: 'Elige el agente inmobiliario adecuado',
          text: 'Ya hemos verificado nuestros agentes inmobiliarios. Sólo tienes que comparar las cotizaciones y hacer una elección.',
          logo: require('chimera/all/themes/blueflow/images/usps/user.svg'),
        },
      ],
      cardTitle: 'Encuentra el agente inmobiliario adecuado en tu región',
      cardText: '¡Compara presupuestos antes de decidir y ahorra hasta un 40%!',
      cardSubtext: 'Compara 6 agentes inmobiliarios en tu región',
    }
  },
}
</script>

<style lang="scss" scoped>
.header--center-bottom .header__container > .container {
  top: 14%;
  @apply md:top-1/4 lg:top-1/3;
}

.page-index .header--white--text .header__card {
  @media (min-width: 541px) {
    @apply max-w-[484px];
  }
}
</style>
