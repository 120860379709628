<template>
  <FormModal class="form-modal--intro form-modal--secondary">
    <template #body>
      <div class="form-modal__logo-container">
        <InlineSvg
          class="form-modal__logo"
          src="images/logo.svg"
          :alt="concept"
        />
      </div>
      <div class="form-modal__title">
        Ahora, vamos a hacerte unas preguntas. Esto nos permitirá encontrar las
        agencias inmobiliarias que mejor se adapten a tus necesidades.
      </div>
    </template>

    <template #footer>
      <t-btn-link
        id="footerNextButton"
        class="footer__next-btn"
        to="/solicitudes-de-cotizacion/tipo-de-consulta"
      >
        Siguiente
      </t-btn-link>
    </template>
  </FormModal>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'

export default {
  components: {
    FormModal,
    InlineSvg,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Inicio cuestionario',
      headDescription:
        'Ahora, vamos a hacerte unas preguntas. Esto nos permitirá encontrar las agencias inmobiliarias que mejor se adapten a tus necesidades.',
      path: '/solicitudes-de-cotizacion/inicio',
      progressValue: 0,
      checkoutStep: 1,
    }
  },
}
</script>
